.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#view {
  background: rgb(245, 248, 251);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 12px 24px 0px;
  font-family: monospace;
  overflow: hidden;
  position: fixed;
  bottom: 32px;
  top: initial;
  right: 32px;
  left: initial;
  width: 500px;
  height: 700px;
  z-index: 999;
  transform: scale(1);
  transform-origin: right bottom;
  transition: transform 0.3s ease 0s;
}

@media screen and (max-width: 768px) {
  #view {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0px;
    transform-origin: right bottom;
  }

}

#chat_button:hover{
  opacity: 0.8;
}

#view.hidden {
  transform: scale(0);
}

#chat_form{
  position: relative;
}

#chat_footer{
  position: absolute;
  bottom: 0px;
  width: -webkit-fill-available;
}

#chat_header{
  display: flex;
  color: white
}

#close_button{
  background-color: transparent;
  border: 0px;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: rgb(255,255,255);
  opacity: 1;
  outline: none;
  padding: 14px 16px 12px;
  position: absolute;
  right: 0px;
  top: 0px;
}

#close_button:hover{
  fill: rgb(180,180,180);
}

#chat_title {
  flex: 2;
  margin-left: 10px
}

#text {
  border-width: 1px 0px 0px;
  border-right-style: initial;
  border-bottom-style: initial;
  border-left-style: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-image: initial;
  border-radius: 0px 0px 10px 10px;
  border-top-style: solid;
  border-top-color: rgb(238, 238, 238);
  box-shadow: none;
  box-sizing: border-box;
  font-size: 16px;
  opacity: 1;
  outline: none;
  padding: 16px 52px 16px 10px;
  width: 100%;
  appearance: none;
}

.submit-button{
  background-color: transparent;
  border: 0px;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: rgb(74, 74, 74);
  opacity: 1;
  outline: none;
  padding: 14px 16px 12px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.submit-button:hover{
  fill: rgb(120, 120, 120);
}

.reset-button{
  background-color: white;
  border: 0px;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: rgb(202, 31, 31);
  opacity: 1;
  outline: none;
  padding: 12px 16px 12px;
  position: absolute;
  right: 30px;
  top: 1px;
}

.reset-button:hover{
  fill: rgb(255, 0, 0);
}

#username{
  margin-bottom: 10px;
}

#chat_button {
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  background: #1d2a39e6;
  bottom: 32px;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 12px 24px 0px;
  display: flex;
  fill: rgb(255, 255, 255);
  height: 56px;
  -webkit-box-pack: center;
  justify-content: center;
  position: fixed;
  right: 32px;
  transform: scale(1);
  transition: transform 0.3s ease 0s;
  width: 56px;
  z-index: 999;
}

#chat_button.hidden{
  transform: scale(0);
}

#chat_header{
  background-color: #1d2a39e6;
}

.chat-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.chat-button img {
  width: 70%;
  height: auto; 
}

.messages {
  padding: 16px;
  overflow: auto;
  height: 75%;
}

.message {
  margin: 15px 0;
  padding: 8px;
  font-size: 16px;
  line-height: 1.5;
  width: fit-content;
  max-width: 70%;
  padding: 12px;
}

.user-message {
  background-color: #009ed9;
  color: #fff;
  margin-left: auto;
  border-radius: 15px 15px 0px 15px;
}
.ai-message {
  background-color: #1d2a39e6;
  color: #fff;
  border-radius: 15px 15px 15px 0px;
}

.loader-container {
  position: absolute;
  right: 7px;
  top: 7px;
}

.loader {
  width: 35px;
  height: 35px;
  border: 3px solid #e8e8e8;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #FF3D00 transparent;
}

#botImg{
  height: 40px;
  min-width: 50px;
  padding-left: 5px;
  padding-top: 5px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
